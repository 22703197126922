<template>
  <v-container class="pa-0">
    <v-list class="text-start" two-line>
      <kurcc-group-inspection-list-item v-for="(group, index) in groups" :key="index" :group="group">
        <v-divider v-if="index !== groups.length -1" slot="divider"/>
      </kurcc-group-inspection-list-item>
    </v-list>
    <kurcc-infinite-loading :filter="[`member_id=${$auth.user().id}`]" :items.sync="groups" action-name="getAllGroups"/>
  </v-container>
</template>

<script>
export default {
  name: 'KurccGroupInspectionList',
  components: {
    KurccInfiniteLoading: () => import('@/modules/app/components/KurccInfiniteLoading'),
    KurccGroupInspectionListItem: () => import('@/modules/groups/components/KurccGroupInspectionListItem')
  },
  data () {
    return {
      groups: []
    }
  }
}
</script>
